<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="clickEvent">
            <el-tab-pane label="众筹详情" name="1" :link="`/manage/raise/edit/${id}`"></el-tab-pane>
            <el-tab-pane label="销售统计" name="2" :link="`/manage/raise/edit/statistics/${id}`"></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    props:{
        name:{
            type: String,
            default: '1'
        }
    },
    data(){
        return{
            activeName: this.name,
            id: this.$route.params.id
        }
    },
    methods:{
        clickEvent(tab){
            if (tab.$attrs.link) this.$router.push(tab.$attrs.link)
        }
    }
}
</script>

<style lang="scss" scoped>
// .el-tabs{
//    ::v-deep .el-tabs__header{
//        border: 0;
//        .el-tabs__nav{
//            border-bottom: 1px solid #E4E7ED;
//            border-radius: 4px;
//            overflow: hidden;
//            .el-tabs__item.is-active{
//                border-bottom: 0;
//                background: $--color-theme;
//                color: #ffffff;
//            }
//        }
//    }
// }
</style>