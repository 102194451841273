<template>
    <div>
        <el-row v-loading="dataLoading" class="info-box">
            <el-col :xl="16">
                <el-row :gutter="40">
                    <el-col :sm="8">
                        <label>目标筹款金额</label> 
                        <span class="color-regular">{{data && data.targetAmountStr || 0.00}}</span>
                    </el-col>
                    <el-col :sm="8">
                        <label>已筹款</label> 
                        <span class="color-regular">{{data && data.reachAmountStr || 0.00}}</span>
                    </el-col>
                    <el-col :sm="8">
                        <label>进度</label> 
                        <span class="color-regular">{{data && data.schedule || 0}}% </span>
                        <el-tag size="mini" type="danger" effect="dark">剩余 {{data && data.days || 0}}天</el-tag>
                    </el-col>
                    <el-col :sm="8">
                        <label>订单总数</label>
                        <span class="color-regular">{{data && data.orderCount || 0}}</span>
                    </el-col>
                    <el-col :sm="8">
                        <label>支持次数</label>
                        <span class="color-regular">{{data && data.supportCount || 0}}</span>
                    </el-col>
                    <el-col :sm="8">
                        <label>支持人数</label>
                        <span class="color-regular">{{data && data.supportPeopleCount || 0}}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    data(){
        return{
            dataLoading:false,
            data:null,
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        //获取汇总数据
        getData(){
            this.dataLoading = true;
            this.$request({
                url: "/raiseSaleStatistice/statistics",
                params: { goodsId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result;
                    } else {
                        this.$message.error(msg || "获取汇总信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取汇总信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../style.scss';
</style>