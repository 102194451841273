<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="16">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input v-model="params.queryValue" class="input-with-select" size="small">
                        <el-select v-model="params.queryType" slot="prepend" placeholder="请选择">
                            <el-option label="订单编号" :value="1"></el-option>
                            <el-option label="回报项名称" :value="2"></el-option>
                            <el-option label="联系手机号码" :value="3"></el-option>
                            <el-option label="收货人姓名" :value="4"></el-option>
                            <el-option label="运单号" :value="5"></el-option>
                        </el-select>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.orderStatus" placeholder="请选择状态" size="small">
                        <el-option value="" label="不限制订单状态"></el-option>
                        <el-option :value="2" label="仅看待发货的"></el-option>
                        <el-option :value="4" label="仅看已发货的"></el-option>
                        <el-option :value="5" label="仅看已完成的"></el-option>
                        <el-option :value="7" label="仅看已取消的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.skuType" placeholder="请选择发货方式" size="small">
                        <el-option value="" label="不限制发货方式"></el-option>
                        <el-option :value="9" label="仅看无需发货的"></el-option>
                        <el-option :value="7" label="仅看网络发送的"></el-option>
                        <el-option :value="8" label="仅看国内包邮的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button type="primary" @click="search" size="small">搜索</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                style="width: 100%"
                v-loading="listLoading"
                size="small"
                header-row-class-name="page-list-table"
            >
                <el-table-column prop="orderStatus" label="订单状态">
                    <template slot-scope="scope">
                        <span :class="orderStatus.get(scope.row.orderStatus)[1]">{{orderStatus.get(scope.row.orderStatus)[0]}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderSn" label="订单编号" min-width="120" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="回报项" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="num" label="支持份数"></el-table-column>
                <el-table-column prop="totalPriceStr" label="总价"></el-table-column>
                <el-table-column prop="telephone" label="联系手机号码" min-width="140">
                    <template slot-scope="scope">
                        +{{scope.row.areaCode.split('_')[0]}} {{scope.row.telephone}}
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="下单备注" min-width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="下单时间" min-width="150"></el-table-column>
                <el-table-column prop="payTime" label="支付时间" min-width="150"></el-table-column>
                <el-table-column prop="skuType" label="发货方式">
                    <template slot-scope="scope">
                        {{skuType.get(scope.row.skuType)}}
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="收货邮箱/地址" min-width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="customerName" label="收货人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="provinceName" label="收货省份"></el-table-column>
                <el-table-column prop="cityName" label="收货城市"></el-table-column>
                <el-table-column prop="expressId" label="快递公司"></el-table-column>
                <el-table-column prop="expressNo" label="运单号"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <template v-if="scope.row.skuType != 9">
                            <el-button
                                v-if="scope.row.orderStatus == 2"
                                type="text"
                                size="small"
                                @click="updateDeliverInfo(scope.row)"
                                >发货</el-button
                            >
                            <el-button
                                v-if="scope.row.orderStatus == 4"
                                type="text"
                                size="small"
                                @click="updateDeliverInfo(scope.row)"
                                >更新发货信息</el-button
                            >
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <div class="fl">
                <el-button type="primary" size="small"  @click="download" :loading="downLoading">导出订单</el-button>
                <el-button type="primary" size="small"  @click="$refs.batchDeliver.show()">批量发货</el-button>
            </div>
            <el-pagination
                v-if="data"
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pageNo"
                :page-sizes="[15, 30, 50]"
                :page-size.sync="params.pageSize"
                layout="sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
        <UpdateDeliverInfo ref="updateDeliverInfo" @onUpdate="getDataList"></UpdateDeliverInfo>
        <BatchDeliver ref="batchDeliver" :id="id" @onUpdate="getDataList"></BatchDeliver>
    </div>
</template>

<script>
import UpdateDeliverInfo from './updateDeliverInfo';
import {downloadFileTmp} from '@/assets/js/commonApi.js';
import BatchDeliver from './batchDeliver';

export default {
    props: {
        id: {
            type: [Number, String],
            default: '',
        },
    },
    components:{UpdateDeliverInfo,BatchDeliver},
    data(){
        return{
            data: null,
            dataList: null,
            listLoading: true,
            downLoading:false,
            params:{
                orderStatus: '',
                pageNo:1,
                pageSize:15,
                queryType:1,
                queryValue:'',
                skuType:''
            }
        }
    },
    computed:{
        orderStatus(){
            return new Map([
                [1,['待处理','']],
                [2,['待发货','color-warning']],
                [3,['部分发货','']],
                [4,['已发货','color-success']],
                [5,['已完成','']],
                [6,['已过期','color-info']],
                [7,['已取消','']],
            ])
        },
        skuType(){
            return new Map([
                [7,'网络发送'],
                [8,'国内包邮'],
                [9,'无需发货']
            ])
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
         //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/raiseSaleStatistice/orderPageList",
                data: { 
                    ...this.params,
                    goodsId: parseInt(this.id) 
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "获取订单信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取订单信息失败");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //更新发货
        updateDeliverInfo(data){
            this.$refs.updateDeliverInfo.show(data);
        },
        //导出
        async download(){
            let filePath;
            this.downLoading = true;
            const res = await this.$request({
                url:'/raiseSaleStatistice/downLoadExcel',
                method: 'post',
                params:{...this.params,goodsId:this.id}
            })
                .catch(err => {
                    this.$message({message: err.message, type: 'error'});
                })
            const{state,msg,result} = res.data;
            if(state == 1 && result){
                filePath = result.url;
            }else{
                this.downLoading = false;
                return this.$message.error(msg || '下载失败');
            }   

            await downloadFileTmp(filePath,result.fileName+'.xlsx');
            this.downLoading = false;
        }
    }
}
</script>