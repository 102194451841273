<template>
    <div v-loading="dataLoading" class="reportBack-box">
        <el-row v-for="(item,index) in data" :key="index" class="info-box card-box">
            <el-col :xl="16">
                <div class="page-group-title">{{item.name}}</div>
                <el-row :gutter="40">
                    <el-col :span="8">
                        <label>总收入</label> 
                        <span class="color-regular">{{item.totalAmountStr || 0}}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>收入占比</label> 
                        <span class="color-regular">{{item.rate || 0}}%</span>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="8">
                        <label>订单总数</label> 
                        <span class="color-regular">{{item.orderCount || 0}}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>支持次数</label> 
                        <span class="color-regular">{{item.supportCount || 0}}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>支持人数</label> 
                        <span class="color-regular">{{item.supportPeopleCount || 0}}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    data(){
        return{
            dataLoading:false,
            data:null,
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        //获取回报项数据
        getData(){
            this.dataLoading = true;
            this.$request({
                url: "/raiseSaleStatistice/reportBack",
                params: { goodsId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result;
                    } else {
                        this.$message.error(msg || "获取回报项信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取回报项信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../style.scss';
.reportBack-box{
    margin-top: 30px;
    .card-box{
        margin-top: 10px;
        border-radius: 4px;
        border: 1px solid $--border-color-base;
        background: rgba(235, 238, 245, 0.2);
        padding: 5px 20px 20px 20px;
    }
}
</style>