<template>
    <div>
        <el-drawer
            title="批量发货"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
                <div class="temp-head clearfix">
                    <span class="fl">导入示例</span>
                    <el-button class="fr" icon="el-icon-download" size="mini" type="text" :loading="downLoading" @click="download">下载模板</el-button>
                </div>
                <div class="temp-table">
                    <el-table
                        :data="tempModel"
                        style="width: 100%"
                        size="small"
                        header-row-class-name="page-list-table"
                    >
                        <el-table-column prop="orderSn" label="订单编号"></el-table-column>
                        <el-table-column prop="expressId" label="快递公司"></el-table-column>
                        <el-table-column prop="expressNo" label="运单号"></el-table-column>
                    </el-table>
                </div>
                <div class="temp-tips MT20">
                    <div><span class="el-icon-info color-warning"></span> 批量发货须知</div>
                    <div class="color-regular">
                        <p>快递公司只能使用模板自带的下拉框进行选择，不支持自定义任意填写</p>
                        <p>如果没有所需的快递公司，请联系秀动进行添加</p>
                        <p>对于网络发送的回报项，快递公司请选择【网络发送】，运单号请填写【网络发送】</p>
                    </div>
                </div>
                <div class="MT20">
                    <el-upload
                        action
                        :http-request="uploadExcel"
                        :auto-upload="false"
                        ref="upload"
                        accept=".xls, .xlsx"
                        :show-file-list="true"
                        :before-upload="beforeUpload"
                        :file-list="fileList"
                        :on-change="onChange"
                        :on-remove="onRemove"
                        :disabled="uploadLoading">
                        <el-button size="small" type="primary" :loading="uploadLoading">{{uploadLoading ? '上传中' : '选择文件'}}</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且最多不超过2W行</div>
                    </el-upload>
                </div>
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="canSubmit"
                    >提交</el-button>
            </div>

             <ErrorMsgDrawer ref="errorMsgDrawer"></ErrorMsgDrawer>    

        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';
import ErrorMsgDrawer from '@/components/ErrorMsgDrawer';
export default {
    props: {
        id: {
            type: [Number, String],
            default: '',
        },
    },
    components:{ErrorMsgDrawer},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            tempModel:[{
                orderSn:'xdyc000000000001',
                expressId:'顺丰',
                expressNo:'000000000000001'
            },{
                orderSn:'xdyc000000000002',
                expressId:'网络发货',
                expressNo:'网络发货'
            }],
            canSubmit:true,
            fileList:[],
            // errMsgShow:false,
            // errMsgList:[]
        }
    },
    methods:{
        show() {
            this.drawerShow = true;
        },
        close(){
            this.fileList = [];
            this.$refs.upload.clearFiles();
            this.drawerShow = false;
        },
        //下载模板
        async download(){
            let filePath;
            this.downLoading = true;
            const res = await this.$request({
                url:'/raiseSaleStatistice/downLoadTemplate',
                method: 'post',
            })
                .catch(err => {
                    this.$message({message: err.message, type: 'error'});
                })
            const{state,msg,result:{url}} = res.data;
            if(state == 1 &&  url){
                filePath = url;
            }else{
                this.downLoading = false;
                return this.$message.error(msg || '下载失败');
            }   

            await downloadFileTmp(filePath,'众筹批量发货模板.'+this.$utils.getFileType(filePath));
            this.downLoading = false;
        },
        onSubmit(){
            this.$refs.upload.submit();
        },
        async uploadExcel(req){
            this.btnLoading = true;
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append('file', req.file);

            let filePath;
            const res = await uploadFileTmp(formData).catch(err => {
                this.$message({message: err.message, type: 'error'});
            }).finally(() => {
                this.uploadLoading = false;
            });
            const{state,msg,result} = res.data;
            if (state == 1 && result) {
                filePath = result.url;
            }else{
                this.$message.error(msg || '上传失败');
            }

            this.$request({
                url:'/raiseSaleStatistice/batchDeliver',
                method: 'post',
                params:{path:filePath,goodsId:this.id}
            })
                .then(res => {
                    const{state,msg,result} = res.data;
                    if (state == 1) {
                        if(result && result.importErrorDetailVOList){
                            // this.errMsgShow = true;
                            // this.errMsgList = result;
                            this.$refs.errorMsgDrawer.show(result);
                        }else{
                            this.onSuccess();
                        }
                        
                    }else{
                        this.$message.error(msg || '上传失败');
                        this.canSubmit = false;
                    }
                })
                .catch(err => {
                    this.canSubmit = false;
                    this.$message({message: err.message, type: 'error'});
                })
                .finally(() => {
                    this.btnLoading = false;
                    this.canSubmit = true;
                });
        },
        beforeUpload(file){
            const type = this.$utils.getFileType(file.name)
            const isType = type === 'xls' || type === 'xlsx';
            if (!isType) {
                this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
            }
            return isType;
        },
        onSuccess(){
            this.$emit('onUpdate');
            this.close();
        },
        onChange(file){
            if( file ){
                this.fileList = [{...file}]
                this.canSubmit = false;
            }else{
                this.canSubmit = true;
            }
        },
        onRemove(){
            this.canSubmit = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
</style>