<template>
    <el-drawer
        :title="itemData && itemData.orderStatus == 2 ? '发货' : '更新发货信息'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                v-if="itemData"
                :model="formDatas"
                :rules="rules"
                ref="formDatas"
                label-position="top"
            >
                <template v-if="itemData.skuType == 7">
                    <p>当前订单需要通过网络发货至对方邮箱: <span class="color-theme">{{itemData.address}}</span></p>
                    <p class="MT10">是否确认完成了发货？</p>
                </template>
                <template v-else>
                    <el-form-item prop="expressId">
                        <span slot="label">
                            快递公司:    
                            <small>如果没有所需的快递公司，请联系秀动进行添加</small>
                        </span>
                        <el-select v-model="formDatas.expressId" size="medium">
                            <el-option v-for="(item,index) in expressList" :key="index" :value="item.expressId" :label="item.expressName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="expressNo" label="运单号:" >
                        <el-input v-model="formDatas.expressNo" placeholder="请输入运单号" size="medium" maxlength="40"></el-input>
                    </el-form-item>
                </template>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading"
                >确定</el-button
            >
        </div>
    </el-drawer>
</template>

<script>
import {fetchExpress} from '@/assets/js/commonApi';
const defaultFormData = {
    deliverType:1,
    expressId:'网络发送',
    expressNo:'网络发送',
    orderDetailId:'',
    orderId:''
}
export default {
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formDatas: {
                ...defaultFormData,
            },
            rules:{
                expressId:[{required: true, message: '请选择快递公司'}],
                expressNo:[{required: true, message: '请输入运单号'}]
            },
            itemData:null,
            expressList:[]
        }
    },
    created(){
        this.getExpressList();
    },
    methods:{
        close(){
            this.formDatas = { ...defaultFormData };
            this.$refs.formDatas.resetFields();
            this.drawerShow = false;
        },
        show(data){
            this.itemData = data;
            if( data.skuType == 7 ){
                this.formDatas = {
                    ...defaultFormData,
                    orderDetailId:data.orderDetailId,
                    orderId:data.orderId
                }
            }else{
                this.formDatas = {
                    ...defaultFormData,
                    ...data,
                    expressId: data.expressId || '',
                    expressNo: data.expressNo || '',
                    deliverType:2
                }
            }
            this.drawerShow = true;
        },
        getExpressList(){
            fetchExpress().then((res) => {
                const {state,result} = res.data;
                if(state == 1){
                    this.expressList = result;
                }
            }).catch(() => {})
        },
        submitForm(){
            this.$refs["formDatas"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: '/raiseSaleStatistice/updateDeliverInfo',
                        data:this.formDatas,
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('onUpdate');
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        }
    }
}
</script>