<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <raise-tab name="2"></raise-tab>
        <div class="page-group-box">
            <div class="page-group-title">销售汇总</div>
            <StatisticsList :id="id"></StatisticsList>
            <ReportBackList :id="id"></ReportBackList>
        </div>
        <div class="page-group-box MT10">
            <div class="page-group-title">订单</div>
            <OrderPageList :id="id" class="MT20"></OrderPageList>
        </div>
    </div>
</template>

<script>
import RaiseTab from '../components/raise-tab.vue'
import StatisticsList from './components/statistics-list'
import ReportBackList from './components/reportBack-list'
import OrderPageList from './components/orderPage-list'
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{RaiseTab,StatisticsList,ReportBackList,OrderPageList},
    data(){
        return{
            redirect: this.$route.query.redirect,
        }
    },
    mounted(){
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
    }
}
</script>

<style lang="scss" scoped>

    
</style>