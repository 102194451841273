<template>
    <el-drawer
        title="错误提示"
        :visible.sync="errMsgShow"
        :append-to-body="true"
        size="auto">
            <div v-if="data" class="drawer-content">
                <div class="tips color-danger">{{data.totalNum}}条记录导入失败，请参考以下错误描述，修改后全部重新导入（以下最多展示100条错误信息）</div>
                <div class="MT20">
                    <el-table
                        :data="data.importErrorDetailVOList"
                        style="width: 100%"
                        size="small"
                        border
                    >
                        <el-table-column prop="row" label="行号"></el-table-column>
                        <el-table-column prop="tips" label="错误描述" min-width="340"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="drawer-footer">
                <el-button type="primary" @click="errMsgShow = false">返回修改</el-button>
            </div>
    </el-drawer> 
</template>

<script>
export default {
    data(){
        return{
            errMsgShow: false,
            data: null,
        }
    },
    methods:{
        show(data){
            this.data = data
            this.errMsgShow = true
        }
    }
}
</script>